import React from "react";
import { getPathname, Hero, Link, SectionContainer } from "@italymondo/core-ui";
import { Stack } from "@mui/material";
import { graphql } from "gatsby";
import { Layout } from "../../components";
import { LegalIndexQuery } from "./index.generated";

type PpLegalIndexPage = {
  data: LegalIndexQuery;
};

export default function LegalIndexPage({
  data: {
    cms: { site },
  },
}: PpLegalIndexPage): React.ReactElement {
  return (
    <Layout>
      <Hero headline="Legal pages" />
      <SectionContainer>
        <Stack alignItems="center" spacing={2} style={{ textAlign: "center" }}>
          {site?.legalPages?.map(
            ({ headline, url }): React.ReactElement => (
              <Link color="primary" to={getPathname(url)} variant="h3">
                {headline}
              </Link>
            )
          )}
        </Stack>
      </SectionContainer>
    </Layout>
  );
}

export const query = graphql`
  query LegalIndex {
    cms {
      site(hostname: "www.italymondo.com") {
        legalPages: pages(contentType: "italymondo_home.IMLegalPage") {
          ... on CMS_IMLegalPage {
            headline
            url
          }
        }
      }
    }
  }
`;
